import { createBlock } from '@/lib/parse/blocks';
import { ImageEntry } from '@/lib/parse/images';
import { Link } from '@/lib/parse/linkField';

export type MediaAndTextType = {
  content: React.ReactNode;
  link?: Link;
  image?: ImageEntry;
  videoUrl?: string;
  flipAlignment: boolean;
  cropType: 'landscape' | 'portrait' | 'default';
};

type MediaAndTextProps = {
  items: MediaAndTextType[];
};

export const useMediaAndTextBlock = createBlock<
  'blocks_mediaAndText_BlockType',
  NoProps,
  MediaAndTextProps
>(({ children = [] }) => {
  const items = children.reduce((results, child) => {
    const item: MediaAndTextType = {
      image: child.imageSingle ?? undefined,
      videoUrl: child.videoUrl ?? undefined,
      content: child.content ?? undefined,
      link: child.linkCta ?? undefined,
      flipAlignment: child.flipAlignment ?? false,
      cropType: (child.cropType as MediaAndTextType['cropType']) ?? 'default',
    };

    results.push(item);

    return results;
  }, [] as MediaAndTextType[]);

  return {
    items,
  };
});
