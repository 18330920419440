import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/lib/parse/blocks';
import Box from '@/polymorphics/Box';
import Grid from '@/polymorphics/Grid';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../../BlockContainer';
import IconCard from './IconCard';
import { useIconGrid } from './useIconGridBlock';

const IconGridBlock = createBlock<'blocks_iconGrid_BlockType'>(
  ({ _blockMeta: meta, heading, content, colorTheme, ...props }) => {
    const { items } = useIconGrid(props);
    const colored = toBoolean(colorTheme) || undefined;

    return (
      <BlockContainer {...{ meta, colorTheme, colorSet: 'primary', colored }}>
        <Box>
          <BlockHeader
            maxWidth="none"
            cx={{ mB: '7' }}
            heading={heading}
            HeadingProps={{ variant: 'h1', cx: { mX: 'auto' } }}
            content={content}
            ContentProps={{
              cx: { width: { desktop: 'twoThirds' }, textAlign: { desktop: 'center' }, mX: 'auto' },
            }}
          />
          {!!items.length && (
            <Grid cx={{ rowGap: '6' }}>
              {items.map((item, idx) => {
                return (
                  <Grid.Col sm={6} md={4} key={idx}>
                    <IconCard
                      cx={{
                        pX: { desktop: '2' },
                      }}
                      {...item}
                    />
                  </Grid.Col>
                );
              })}
            </Grid>
          )}
        </Box>
      </BlockContainer>
    );
  }
);

export default IconGridBlock;
