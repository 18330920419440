import { useForwardedRef } from '@/lib/helpers/useForwardedRef';
import { BlockMeta } from '@/lib/parse/blocks';
import { isEntry } from '@/lib/parse/entries';
import { useView } from '@/lib/store/hooks';
import Box, { BoxProps } from '@/polymorphics/Box';
import Container, { ContainerProps } from '@/polymorphics/Container';
import { ColorTheme } from '@/style/partials/palette.css';
import { Sprinkles } from '@/style/sprinkles.css';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import React from 'react';
import * as style from './BlockContainer.css';
import { useBlock, useBlocksContext } from './useBlock';

export type BlockContainerProps = BoxProps<
  'section',
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    disableContainer?: boolean;
    disableTransition?: boolean;
    ContainerProps?: ContainerProps;
    maxWidth?: ContainerProps['maxWidth'];
    meta?: BlockMeta;
    marginY?: Sprinkles['mY'];
  }
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableContainer,
    cx,
    maxWidth,
    ContainerProps,
    colorSet,
    colorTheme,
    colored,
    meta,
    marginY,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  const blocksContext = useBlocksContext();
  const ref = useForwardedRef(forwardedRef);
  const { anchor, block } = useBlock();
  const pathname = usePathname();

  const isLast = !!block._blockMeta?.last;
  const isFirst = !!block._blockMeta?.first;

  const maybeBlockBackgroundImage = maybeGet(block, 'bgImage') || undefined;
  const maybeBlockColorOpacity = maybeGet(block, 'colorTheme') || undefined;
  const maybeBlockColorSet = colored && colorSet ? colorSet : undefined;

  const maybeBlockBg = maybeBlockColorOpacity || maybeBlockColorSet || maybeBlockBackgroundImage;

  const maybePrevBlockBg = maybeGet(block._blockMeta?.previousBlock, 'colorTheme');

  const sameBg = maybePrevBlockBg === maybeBlockBg;

  const page = useView();
  const isArticle = isEntry(page, 'article');
  const isReducedWidth = isArticle || isEntry(page, 'standard');

  const noTopMargin = isArticle;

  const blockSpace = isArticle ? '7' : '8';
  const defaultHeaderTopSpace =
    maybeGet(page, 'headerType') === 'default' ? '7' : isArticle ? '7' : '8';

  // If the page is either an article or default page, the width of the container is reduced or if colored is true
  const width = maxWidth ? maxWidth : colored ? 'xl' : isReducedWidth ? 'md' : undefined;

  const customColorTheme: ColorTheme = colorTheme
    ? colorTheme
    : pathname === '/nexus/'
    ? 'lightGreen'
    : 'light';

  let inner = <>{children}</>;

  if (!disableContainer && !blocksContext.disableContainers)
    inner = (
      <Container
        {...{
          maxWidth: width,
          ...ContainerProps,
        }}>
        {children}
      </Container>
    );

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
    })
  );

  return (
    <>
      {/* {isDev && <code>{block?.__typename.split('_')[1]}</code>} */}
      <Box
        as="section"
        id={anchor}
        data-block={meta?.typename.split('_')[1]}
        ref={ref}
        {...{ colorTheme: customColorTheme, ...props }}
        cx={{
          marginTop: marginY || maybeBlockBg || isFirst ? 'none' : blockSpace,
          // marginBottom: maybeBlockBg || isLast ? 'none' : '8', // include last block conditional
          marginBottom: marginY || maybeBlockBg ? 'none' : blockSpace,
          paddingBottom: maybeBlockBg ? blockSpace : 'none',
          paddingTop:
            (maybeBlockBg && !sameBg) || (isFirst && !noTopMargin) ? defaultHeaderTopSpace : 'none',
          ...cx,
        }}
        className={className}
        {...props}>
        {inner}
      </Box>
    </>
  );
});
