'use client';

import Image from '@/components/Image';
import { Entry } from '@/lib/parse/entries';
import Box from '@/polymorphics/Box';
import Btn, { BtnIcon } from '@/polymorphics/Btn';
import Container from '@/polymorphics/Container';
import Grid from '@/polymorphics/Grid';
import Txt from '@/polymorphics/Txt';
import { colorThemeVars } from '@/style/partials/palette.css';
import { useBreakpointMax } from '@/style/utils/useBreakpoints';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import * as styles from './Cta.css';

export default function Cta({
  heading,
  content,
  linkCta,
  imageSingle,
  colorTheme = 'royalBlue',
}: Entry<'ctaBlock'>) {
  const lg = useBreakpointMax('lg');
  const blank = linkCta?.target === '_blank';

  return (
    <Box colorTheme={colorTheme} className={styles.root}>
      <Container maxWidth="xl" disablePadding>
        <Grid
          cx={{ pT: '8', pB: { mobile: imageSingle ? 'none' : '8', desktop: '8' }, rowGap: '8' }}>
          <Box cx={{ pX: '4', width: { desktop: 'half' } }}>
            <Txt as="p" variant="h2">
              {heading}
            </Txt>
            <Txt html>{content}</Txt>
            {!!linkCta && (
              <Btn
                variant="pill"
                size="lg"
                colorTheme={colorTheme}
                colored
                focus
                {...linkCta}
                cx={{ mT: '3' }}>
                {linkCta?.children}
                <BtnIcon type={blank ? 'externalLink' : 'arrow'} size="lg" />
              </Btn>
            )}
          </Box>
          {lg && <Image alt="cta-image" fixedRatio {...imageSingle} />}
        </Grid>
      </Container>
      {imageSingle && !lg && (
        <Box className={styles.imageWrapper}>
          <Image alt="cta-image" fixedRatio {...imageSingle} />
        </Box>
      )}
      {!lg && !imageSingle && (
        <Box
          colorTheme={colorTheme}
          className={styles.circle}
          style={assignInlineVars({ [styles.shadow]: colorThemeVars.primary.text })}
        />
      )}
    </Box>
  );
}
