import BlockHeader from '@/components/BlockHeader';
import Image from '@/components/Image';
import Video from '@/components/Video';
import { createBlock } from '@/lib/parse/blocks';
import Box from '@/polymorphics/Box';
import Btn from '@/polymorphics/Btn';
import Grid from '@/polymorphics/Grid';
import Txt from '@/polymorphics/Txt';
import { toBoolean } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../../BlockContainer';
import * as styles from './MediaAndTextBlock.css';
import { useMediaAndTextBlock } from './useMediaAndTextBlock';

const MediaAndTextBlock = createBlock<'blocks_mediaAndText_BlockType'>(
  ({ heading, content, _blockMeta: meta, colorTheme, ...props }) => {
    const { items } = useMediaAndTextBlock(props);
    const colored = toBoolean(colorTheme) || undefined;

    return (
      <BlockContainer {...{ meta, colorTheme, colorSet: 'primary', colored }}>
        <Box>
          <BlockHeader
            className={styles.header}
            maxWidth="none"
            heading={heading}
            HeadingProps={{
              variant: 'h1',
              textAlign: 'center',
              cx: {
                mX: 'auto',
                width: { desktop: 'threeFifths' },
                textAlign: { desktop: 'center' },
              },
            }}
            content={content}
            ContentProps={{
              textAlign: 'center',
              cx: { mX: 'auto', width: { desktop: 'twoThirds' } },
            }}
          />
          {items.map((item, idx) => {
            return (
              <Grid
                key={idx}
                cx={{
                  mB: items.length - 1 === idx ? 'none' : '7',
                  rowGap: '6',
                }}>
                <Grid.Col md={6} className={styles.text({ flipAlignment: item.flipAlignment })}>
                  <Box>
                    <Txt html>{item.content}</Txt>
                    {item.link && (
                      <Btn
                        cx={{ mT: '5' }}
                        variant="pill"
                        colorTheme={colorTheme}
                        colorSet="primary"
                        colored
                        size="lg"
                        iconType="arrow"
                        iconSize="lg"
                        focus
                        {...item.link}
                      />
                    )}
                  </Box>
                </Grid.Col>
                <Grid.Col md={6} className={styles.image({ flipAlignment: item.flipAlignment })}>
                  <Box
                    className={styles.imageWrapper({
                      translate: item.cropType === 'landscape' || item.cropType === 'portrait',
                    })}>
                    {item.videoUrl ? (
                      <Video videoUrl={item.videoUrl} transform={item.cropType} />
                    ) : (
                      <Image
                        alt=""
                        // fixedRatio
                        flip
                        tall={item.cropType === 'portrait'}
                        short={item.cropType === 'landscape'}
                        shadowSvg={false}
                        {...item.image}
                      />
                    )}
                  </Box>
                </Grid.Col>
              </Grid>
            );
          })}
        </Box>
      </BlockContainer>
    );
  }
);

export default MediaAndTextBlock;
