'use client';

import Accordion from '@/components/Accordion';
import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/lib/parse/blocks';
import Btn from '@/polymorphics/Btn';
import { BlockContainer } from '../../BlockContainer';
import { useAccordionBlock } from './useAccordionBlock';

const AccordionBlock = createBlock<'blocks_accordion_BlockType'>(
  ({ _blockMeta: meta, heading, linkCta, ...props }) => {
    const { items } = useAccordionBlock(props);

    return (
      <BlockContainer {...{ meta }}>
        <BlockHeader maxWidth="none" heading={heading} cx={{ pB: '4' }} />
        <Accordion items={items} />
        {linkCta && (
          <Btn
            cx={{ mT: '6' }}
            variant="pill"
            colorTheme="default"
            colorSet="primary"
            colored
            size="lg"
            iconType="arrow"
            iconSize="lg"
            focus
            {...linkCta}
          />
        )}
      </BlockContainer>
    );
  }
);

export default AccordionBlock;
