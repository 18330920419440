import Shim, { ShimProps } from '../Shim';
import ShortSVGTransform from './ShortSVGTransform';
import TallSVGTransform from './TallSVGTransform';
import { VideoPlayer } from './VideoPlayer';

export type VideoProps = ShimProps<
  'div',
  {
    videoUrl?: Maybe<string>;
    controls?: boolean;
    playing?: boolean;
    loop?: boolean;
    muted?: boolean;
    transform?: 'default' | 'portrait' | 'landscape';
  }
>;
const Video = ({
  videoUrl,
  controls = true,
  playing = false,
  loop = false,
  muted = false,
  ratio = 'video',
  transform = 'default',
  ...props
}: VideoProps) => {
  if (!videoUrl) return <></>;

  if (transform === 'landscape') return <ShortSVGTransform url={videoUrl} shadow={false} />;

  if (transform === 'portrait') return <TallSVGTransform url={videoUrl} shadow={false} />;

  return (
    <Shim ratio={ratio} {...props}>
      <VideoPlayer
        {...{
          url: videoUrl,
          controls,
          playing,
          loop,
          muted,
        }}
      />
    </Shim>
  );
};

export default Video;
